<template>
    <div>
        <div class="stuBackSchool A4Container flexCloumnCenter pageBreak_no" v-for="(item,index) in info" :key="index">
            <div class="title">实习生违纪处理情况统计表</div>
            <div class="form">
                <el-row class="el-row">
                    <el-col :span="3" class="flexCenter cell">学号</el-col>
                    <el-col :span="3" class="flexCenter cell">姓名</el-col>
                    <el-col :span="6" class="flexCenter cell">实习单位</el-col>
                    <el-col :span="4" class="flexCenter cell">违纪日期</el-col>
                    <el-col :span="8" class="flexCenter cell">情况说明</el-col>
                </el-row>
                <el-row class="el-row" v-for="(items,indexs) in item" :key="indexs">
                    <el-col :span="3" class="flexCenter cell">{{ items.stu_num }}</el-col>
                    <el-col :span="3" class="flexCenter cell">{{ items.stu_name }}</el-col>
                    <el-col :span="6" class="flexCenter cell">{{ items.enter }}</el-col>
                    <el-col :span="4" class="flexCenter cell">{{ items.discip_date }}</el-col>
                    <el-col :span="8" class="flexCenter cell">{{ items.discip_desc }}</el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'stuBackSchool',
    data () {
        return {
            dataover: false,
            userid: ''
        }
    },
    props: {
        info: {
            type: Array,
            default: []
        }
    },
    mounted () {
    },
    methods: {}
}
</script>

<style lang="less" type="text/less">
.stuBackSchool .el-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.stuBackSchool .el-row {
    padding: 0;
    margin: 0;
}
</style>
<style lang="less" type="text/less" scoped>
.stuBackSchool {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .el-row {
            border-top: 1px solid #000;
            border-left: 1px solid #000;

            .cell {
                height: 42px;
                border-right: 1px solid #000;
                word-break: break-all;
            }
        }

        .el-row:last-of-type {
            border-bottom: 1px solid #000;

        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
</style>
