<template>
    <div>
        <div class="stuRolls A4Container flexCloumnCenter pageBreak_no" v-for="(item,index) in info" :key="index">
            <div class="trans">
                <div class="title">实&nbsp;习&nbsp;生&nbsp;名&nbsp;册</div>
                <div class="form">
                    <el-row class="el-row">
                        <el-col :span="2" class="flexCenter cell">学号</el-col>
                        <el-col :span="2" class="flexCenter cell">班级</el-col>
                        <el-col :span="2" class="flexCenter cell">姓名</el-col>
                        <el-col :span="1" class="flexCenter cell">性别</el-col>
                        <el-col :span="2" class="flexCenter cell">出生年月</el-col>
                        <el-col :span="4" class="flexCenter cell">家庭住址</el-col>
                        <el-col :span="3" class="flexCenter cell">联系电话</el-col>
                        <el-col :span="4" class="flexCenter cell">实习单位及联系人</el-col>
                        <el-col :span="3" class="flexCenter cell">联系电话</el-col>
                        <el-col :span="1" class="flexCenter cell">备注</el-col>
                    </el-row>
                    <el-row class="el-row" v-for="(items,indexs) in item" :key="indexs">
                        <el-col :span="2" class="flexCenter cell" style="padding: 0 3px;">{{ items.stu_code }}</el-col>
                        <el-col :span="2" class="flexCenter cell" style="padding: 0 3px;">{{ items.class_name }}</el-col>
                        <el-col :span="2" class="flexCenter cell" style="padding: 0 3px;">{{ items.stu_name }}</el-col>
                        <el-col :span="1" class="flexCenter cell" style="padding: 0 3px;">{{ items.gender==1?'男':(items.gender==2?'女':'') }}</el-col>
                        <el-col :span="2" class="flexCenter cell" style="padding: 0 0px;">{{ items.birthday }}</el-col>
                        <el-col :span="4" class="flexCenter cell" style="padding: 0 3px;">{{ items.jtzz }}</el-col>
                        <el-col :span="3" class="flexCenter cell" style="padding: 0 3px;">{{ items.phone }}</el-col>
                        <el-col :span="4" class="flexCenter cell" style="padding: 0 3px;">{{ items.firm_name }}-{{ items.firm_content_name }}</el-col>
                        <el-col :span="3" class="flexCenter cell" style="padding: 0 3px;">{{ items.firm_content_tel }}</el-col>
                        <el-col :span="1" class="flexCenter cell" style="padding: 0 3px;"></el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'roll',
    data () {
        return {
            dataover: false,
            userid: ''
        }
    },
    props: {
        info: {
            type: Array,
            default: []
        }
    },
    mounted () {},
    methods: {}
}
</script>

<style lang="less" type="text/less">
.stuRolls .ant-col{
    display: flex;
}
</style>
<style lang="less" type="text/less" scoped>
.stuRolls {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;
    .title{
        font-size: 20px;
        line-height: 50px;
    }
    .form{
        width: 100%;
        height: calc( 100% - 50px );

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }
        .el-row{
            height: calc( 100% / 11 );
            border-top: 1px solid #000;
            border-left: 1px solid #000;
            .cell{
                height: 100%;
                border-right: 1px solid #000;
                word-break: break-all;
            }
        }
        .el-row:last-of-type{
            border-bottom: 1px solid #000;

        }
    }
}
.trans{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 23px;
    transform: rotate(-90deg);

}
</style>
