<template>
  <div class="workPlan A4Container flexCloumnSingleCenter pageBreak_no">
    <div class="title flexCenter">实习管理工作计划</div>
    <div class="form">
      <!-- <div class="textIndent2" v-html="info.sxjh ? info.sxjh.plan_desc : '-'"></div> -->

      <div class="content" style="width:100%;height: 100%;overflow: hidden;box-sizing: border-box;position: relative;">
        <div id="divCntnrA" style="width:100%;height: 100%;overflow: hidden;">
          <div id="resizeDivA"  v-html="info.sxjh ? info.sxjh.plan_desc : '-'"></div>
          <!-- <div id="resizeDivA"> -->

            <!-- <div class='textIndent2' style="width: 100%;word-break: break-all;white-space: pre-wrap;"> -->
            <!-- <div v-html="info.sxjh ? info.sxjh.plan_desc : '-'"></div> -->
            <!-- {{ info.sxjh ? info.sxjh.plan_desc : '-' }} -->
            <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>


      <div class="posDate">{{ info.sxjh ? info.sxjh.create_date : '' }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cover',
  props: {
    dataInfo: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      dataover: false,
      userid: '',
      info: [],
    }
  },
  mounted() {
    console.log(58, 1, this.dataInfo)
    let _this = this
    setTimeout(function () {
      let info = JSON.parse(JSON.stringify(_this.dataInfo))
      _this.info = info
      _this.$nextTick(() => {
        console.log(65, _this.info)
        _this.resizeA()
      })
    }, 500)
  },
  methods: {
    stripscript(s) {
      var pattern = new RegExp('\n')
      var rs = ''
      for (var i = 0; i < s.length; i++) {
        rs = rs + s.substr(i, 1).replace(pattern, '')
      }
      return rs
    },
    resizeA() {
      let id1 = 'divCntnrA'
      let id2 = 'resizeDivA'

      let divHeight = document.getElementById(id1).offsetHeight
      let textHeight = document.getElementById(id2).offsetHeight
      // console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 7) {
        let pTag = document.getElementById(id2).querySelectorAll('p');
        let olTag = document.getElementById(id2).querySelectorAll('ol');
        let pLen = pTag.length
        let olLen = olTag.length
        // console.log(7575, pLen, olLen)
        if (pTag) {
          for (let tidx = 0; tidx < pLen; tidx++) {
            pTag[tidx].style.margin = '0px';
            pTag[tidx].style.fontSize = `${18 - i}px`;
            // console.log("🌰", tidx);
          }
        }
        if (olTag) {
          for (let olidx = 0; olidx < olLen; olidx++) {
            olTag[olidx].style.margin = '0px';
            olTag[olidx].style.fontSize = `${18 - i}px`;
            // console.log("🍑", olidx);
          }
        }
        document.getElementById(id2).style.fontSize = `${18 - i}px`
        textHeight = document.getElementById(id2).offsetHeight
        i++

      }
    },
  }
}
</script>