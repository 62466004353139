<template>
  <div class="coverPage A4Container flexCenter pageBreak_no">
    <div class="flexCloumnBetween" style="width: 80%;height: 80%;">

      <div>
        <div class="subTitle">{{ dataInfo.xuerxiao || '南京市中等职业学校' }}</div>
        <div class="title">教&nbsp;师&nbsp;管&nbsp;理&nbsp;手&nbsp;册</div>
      </div>
      <div class="form">
        <div class="formItem flexStart">
          <div class="label">学校</div>
          <div class="input">{{ dataInfo.xuerxiao || '-' }}</div>
        </div>
        <!-- <div class="formItem flexStart">
          <div class="label">院系</div>
          <div class="input">{{ dataInfo.yuanxi || '-' }}</div>
        </div> -->
        <div class="formItem flexStart">
          <div class="label">专业</div>
          <div class="input">{{ dataInfo.yuanxi || '-' }}</div>
        </div>
        <div class="formItem flexStart">
          <div class="label">班级</div>
          <div class="input">{{ dataInfo.class_name || '-' }}</div>
        </div>
        <div class="formItem flexStart">
          <div class="label">教师</div>
          <div class="input">{{ dataInfo.username || '-' }}</div>
        </div>
        <div class="formItem flexStart">
          <div class="label">实习时间</div>
          <div class="input">{{ dataInfo.practice_start_date }} 至 {{ dataInfo.practice_end_date }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cover',
  props: {
    dataInfo: {
      type: Object,
      default: {}
    }
  }
}
</script>
