<template>
    <div>
        <div class="stuBackSchool A4Container flexCloumnCenter pageBreak_no" v-for="(item,index) in info" :key="index">
            <div class="title">实习生返校情况记录</div>
            <div class="form">
                <el-row class="el-row1">
                    <el-col :span="4" class="flexCenter cell">姓名</el-col>
                    <el-col :span="20" class="flexCenter">
                        <el-row class="col-row">
                            <el-col :span="2" class="flexCenter cell">7月</el-col>
                            <el-col :span="2" class="flexCenter cell">8月</el-col>
                            <el-col :span="2" class="flexCenter cell">9月</el-col>
                            <el-col :span="2" class="flexCenter cell">10月</el-col>
                            <el-col :span="2" class="flexCenter cell">11月</el-col>
                            <el-col :span="2" class="flexCenter cell">12月</el-col>
                            <el-col :span="2" class="flexCenter cell">1月</el-col>
                            <el-col :span="2" class="flexCenter cell">2月</el-col>
                            <el-col :span="2" class="flexCenter cell">3月</el-col>
                            <el-col :span="2" class="flexCenter cell">4月</el-col>
                            <el-col :span="2" class="flexCenter cell">5月</el-col>
                            <el-col :span="2" class="flexCenter cell">6月</el-col>
                        </el-row>
                    </el-col>

                </el-row>
                <el-row class="el-row1" v-for="(items,indexs) in item" :key="indexs">
                    <el-col :span="4" class="flexCenter cell">{{ items.stu_name }}</el-col>
                    <el-col :span="20" class="flexCenter">
                        <el-row class="col-row">
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_07 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_08 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_09 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_10 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_11 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_12 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_01 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_02 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_03 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_04 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_05 ? '✓' : '' }}</el-col>
                            <el-col :span="2" class="flexCenter cell">{{ items.back_month_06 ? '✓' : '' }}</el-col>
                        </el-row>
                    </el-col>
                </el-row>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'stuBackSchool',
    data () {
        return {
            dataover: false,
            userid: ''
        }
    },
    props: {
        info: {
            type: Array,
            default: []
        }
    },
    mounted () {
    },
    methods: {}
}
</script>

<style lang="less" type="text/less">
.stuBackSchool .el-col {
    display: flex;
    padding: 0;
    margin: 0;
}

.stuBackSchool .el-row {
    padding: 0;
    margin: 0;
}
</style>
<style lang="less" type="text/less" scoped>
.stuBackSchool {
    font-size: 20px;
    box-sizing: border-box;
    margin: 0 auto;

    .title {
        font-size: 20px;
        line-height: 50px;
    }

    .form {
        width: calc(100% - 50px);
        height: calc(100% - 50px);

        div {
            font-size: 14px;
            color: #323232;
            box-sizing: border-box;
        }

        .el-row1 {
            border-top: 1px solid #000;
            border-left: 1px solid #000;

            .cell {
                height: 42px;
                border-right: 1px solid #000;
                word-break: break-all;
            }
        }

        .el-row1:last-of-type {
            border-bottom: 1px solid #000;

        }
    }

    .col-row {
        width: 100%;
        margin: 0;
        padding: 0;
    }
}
</style>
