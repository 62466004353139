<template>
  <div>
    <div class="pdfok" id="pdfok" v-if="pdfok"></div>
    <div id="printMe" style=" width: 21cm; margin: 0 auto;border: 0;">
      <Cover v-if="show_dev" :dataInfo="dataInfo"></Cover>
      <div v-if="show_dev" class="A4Container pageBreak_no"></div>
      <Rules v-if="show_dev"></Rules>
      <WorkPlan v-if="show_dev" :dataInfo="dataInfo"></WorkPlan>

      <stuRolls v-if="show_dev" :info="rolls"></stuRolls>

      <stuMonthEvaluate v-if="show_dev" :info="stu_kaohe"></stuMonthEvaluate>

      <stuContact v-if="show_dev" :info1="stu_lianxi"></stuContact>

      <stuBest v-if="show_dev" :info="youxiu_shixisheng"></stuBest>

      <div v-if="show_dev" class="workPlan A4Container flexCloumnSingleCenter" v-for="(item, index) in shixi_anli"
        :key="index">
        <ClassicCase :dataInfo="item"></ClassicCase>
      </div>
      <weiJiSituation v-if="show_dev" :info="stu_weiji"></weiJiSituation>
      <stuBackSchool v-if="show_dev" :info="fanxiao"></stuBackSchool>
      <div v-if="show_dev1" class="workPlan A4Container flexCloumnSingleCenter pageBreak_no">
        <div class="title flexCenter">实习管理工作总结</div>
        <div class="form" style="padding: 0!important;">
          <el-row class="el-row" style="line-height: 40px;border-bottom:1px solid #000;">
            <el-col :span="6" class="flexStart" style="border-right:1px solid #000;padding-left: 10px;">班级：{{
      dataInfo.sxzj ? dataInfo.class_name : '' }}
            </el-col>
            <el-col :span="6" class="flexStart" style="border-right:1px solid #000;padding-left: 10px;">教师：{{
      dataInfo.sxzj ? dataInfo.username : '' }}
            </el-col>
            <el-col :span="12" class="flexStart" style="padding-left: 10px;display: flex!important;">
              <div>实习时间：</div>
              <div class="flex_1 flexCenter">
                {{ dataInfo.sxzj ? dataInfo.practice_start_date : '' }} 至
                {{ dataInfo.sxzj ? dataInfo.practice_end_date : '' }}
              </div>
            </el-col>
          </el-row>
          <div class="textIndent2" style="padding: 10px;line-height: unset;"
            v-html="dataInfo.sxzj ? dataInfo.sxzj.plan_desc : ''">
          </div>
          <div class="posDate">{{ dataInfo.sxzj ? dataInfo.sxzj.create_date : '' }}</div>
        </div>
      </div>
    </div>

    <el-button v-if="false" v-print="'#printMe'" type="primary" style="position: fixed;top: 0;left: 0;z-index: 999;">打印
    </el-button>
  </div>
</template>

<script>
import stuRolls from "./com_tch/stuRolls";
import stuMonthEvaluate from "./com_tch/stuMonthEvaluate";
import stuContact from "./com_tch/stuContact";
import stuBest from "./com_tch/stuBest";
import weiJiSituation from "./com_tch/weiJiSituation";
import stuBackSchool from "./com_tch/stuBackSchool";

import Cover from "./com_tch/cover";
import Rules from "./com_tch/rules";
import WorkPlan from "./com_tch/workPlan";
import ClassicCase from "./com_tch/classicCase";

export default {
  name: "export",
  components: {
    Cover,
    Rules,
    WorkPlan,
    ClassicCase,
    stuRolls,
    stuMonthEvaluate,
    stuContact,
    stuBest,
    stuBackSchool,
    weiJiSituation
  },
  data() {
    return {
      pdfok: false,
      show_dev: true,
      show_dev1: true,
      printObj: {
        id: "printMe",
        popTitle: "",
        extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
      },
      userid: "",
      cover: {},
      rolls: [],
      stu_kaohe: [],
      stu_lianxi: [],
      shixi_anli: [{}],
      youxiu_shixisheng: [],
      stu_weiji: [],
      fanxiao: [],
      dataInfo: {}
    };
  },
  mounted() {
    let userid = this.getParams("id");
    let pmid = this.getParams("pmid");
    this.getAllPickerVal(pmid).then(rr => {
      this.getDataList(userid, pmid, rr);
    });
  },
  methods: {
    getAllPickerVal(pmid) {
      return new Promise(resolve => {
        let arrForm = [],
          arrState = [];
        //巡访形式&&巡访情况
        this.$http
          .post("/api/sys_param_list", {
            param_type: "'VISIT_FORM','VISIT_SITUATION'",
            pmid: parseInt(pmid)
          })
          .then(res => {
            res.data &&
              res.data.map((item, index, arr) => {
                if (item.param_type == "VISIT_FORM") {
                  arrForm.push(item);
                }
                if (item.param_type == "VISIT_SITUATION") {
                  arrState.push(item);
                }
              });
            resolve({
              arrForm,
              arrState
            });
          });
      });
    },

    //过滤特殊字符, 校验所有输入域是否含有特殊符号
    stripscript(s) {
      var mdash = "&mdash;";
      var nbsp = "&nbsp;";
      var quot = "&quot;";
      var ldquo = "&ldquo;";
      var rdquo = "&rdquo;";
      var amp = "&amp;";
      var lt = "&lt;";
      var gt = "&gt;";

      var rs = "";
      rs = s.replace(new RegExp(mdash, "g"), "-");
      rs = rs.replace(new RegExp(nbsp, "g"), "");
      rs = rs.replace(new RegExp(quot, "g"), '"');
      rs = rs.replace(new RegExp(ldquo, "g"), "”");
      rs = rs.replace(new RegExp(rdquo, "g"), "“");
      rs = rs.replace(new RegExp(amp, "g"), "&");
      rs = rs.replace(new RegExp(lt, "g"), "<");
      rs = rs.replace(new RegExp(gt, "g"), ">");
      return rs;
    },
    getDataList(userid, pmid, rr) {
      let _this = this;
      let arrForm = rr ? rr.arrForm : [];
      this.$http
        .post("/api/initteacherdatapdf", {
          teacher_id: parseInt(userid),
          pmid: parseInt(pmid)
        })
        .then(res => {
          if (res.data) {
            let rData = res.data;

            let teacher_info = rData.teacher_info ? rData.teacher_info : {};
            this.dataInfo = Object.assign(
              {},
              { ...teacher_info },
              {
                xuerxiao: rData.xuerxiao,
                sxjh: rData.sxjh,
                sxzj: rData.sxzj
              }
            );
            if (rData.sxgldxalList && rData.sxgldxalList.length > 0) {
              let arr = [];
              rData.sxgldxalList.map(a => {
                if (a.report_time) {
                  let dateArr = a.report_time.substr(0, 10).split("-");
                  a.report_time = `${dateArr[0]}年${dateArr[1]}月${dateArr[2]}日`;
                }
                arr.push(a);
              });
              this.shixi_anli = arr;
            }

            if (rData.stu_list && rData.stu_list.length > 0) {
              if (rData.stu_list.length < 10) {
                let len = 10 - rData.stu_list.length;
                for (let i = 0; i < len; i++) {
                  rData.stu_list.push({});
                }
              }
              this.rolls = this.util_spliceArrByN(rData.stu_list, 10);
            } else {
              let arr = [];
              let len = 10;
              for (let i = 0; i < len; i++) {
                arr.push({});
              }
              this.rolls = this.util_spliceArrByN(arr, 10);
            }

            if (rData.sxdwdcb && rData.sxdwdcb.length > 0) {
              let arr = [];
              let groupArr = this.groupBy(rData.sxdwdcb, function (item) {
                return [item.stu_code];
              });
              groupArr.map(a => {
                let obj = {};
                let totalScore = 0;
                a.map((b, indexb) => {
                  obj.stu_code = b.stu_code;
                  obj.stu_name = b.stu_name;
                  let key = `month_${b.month}`;
                  obj[key] = b.score ? b.score : 0;
                  totalScore += b.score ? b.score : 0;
                  obj.totalScore = totalScore
                    ? (totalScore / 12).toFixed(2)
                    : 0;
                });
                arr.push(obj);
              });
              if (arr.length < 20) {
                let len = 20 - arr.length;
                for (let i = 0; i < len; i++) {
                  arr.push({});
                }
              }
              this.stu_kaohe = this.util_spliceArrByN(arr, 20);
            } else {
              let arr = [];
              let len = 20;
              for (let i = 0; i < len; i++) {
                arr.push({});
              }
              this.stu_kaohe = this.util_spliceArrByN(arr, 20);
            }


            if (rData.xunfang_list && rData.xunfang_list.length > 0) {
              let arr = [];
              rData.xunfang_list.map(a => {
                if (a.stu_list && this.isJSON(a.stu_list)) {
                  let stu_list = JSON.parse(a.stu_list);
                  let names = this._lo.map(stu_list, n => {
                    return n.stu_name;
                  });
                  a.stu_name = names.join(",");
                }
                if (a.visit_date) {
                  let dateArr = a.visit_date.substr(0, 10).split("-");
                  a.contact_time = `${dateArr[0]}年${dateArr[1]}月${dateArr[2]}日`;
                }

                arrForm &&
                  arrForm.length > 0 &&
                  arrForm.map(ff => {
                    if (ff.param_value == a.visit_form) {
                      a.visit_form = ff.param_desc;
                    }
                  });

                if (a.fieldname) {
                  a.fieldname = JSON.parse(a.fieldname);
                  // console.log(275, a.fieldname)
                  a.fieldname.map((option, i) => {
                    if (i != 0) {
                      if (option.fileUrl) option.fileUrl += `?x-oss-process=image/resize,m_fill,h_150,w_110`;
                      if (option.src) option.src += `?x-oss-process=image/resize,m_fill,h_150,w_110`;
                    }
                  });
                } else {
                  a.fieldname = [];
                }
                arr.push(a);
              });
              if (arr.length < 3) {
                let len = 3 - arr.length;
                for (let i = 0; i < len; i++) {
                  arr.push({});
                }
              }
              this.stu_lianxi = this.util_spliceArrByN(arr, 3);
            } else {
              let arr = [];
              let len = 3;
              for (let i = 0; i < len; i++) {
                arr.push({});
              }
              this.stu_lianxi = this.util_spliceArrByN(arr, 3);
            }
            console.log(270, this.stu_lianxi)
            // return
            if (rData.yxsxsList && rData.yxsxsList.length > 0) {
              let arr = rData.yxsxsList;
              if (arr.length < 3) {
                let len = 3 - arr.length;
                for (let i = 0; i < len; i++) {
                  arr.push({});
                }
              }
              this.youxiu_shixisheng = this.util_spliceArrByN(arr, 2);
            } else {
              let arr = [];
              let len = 3;
              for (let i = 0; i < len; i++) {
                arr.push({});
              }
              this.youxiu_shixisheng = this.util_spliceArrByN(arr, 2);
            }

            if (rData.weiji && rData.weiji.length > 0) {
              rData.weiji.map(a => {
                if (a.discip_date) {
                  a.discip_date = a.discip_date.substr(0, 10);
                }
              });
              if (rData.weiji.length < 20) {
                let len = 20 - rData.weiji.length;
                for (let i = 0; i < len; i++) {
                  rData.weiji.push({});
                }
              }
              this.stu_weiji = this.util_spliceArrByN(rData.weiji, 20);
            } else {
              let arr = [];
              let len = 20;
              for (let i = 0; i < len; i++) {
                arr.push({});
              }
              this.stu_weiji = this.util_spliceArrByN(arr, 20);
            }

            if (rData.fanxiao && rData.fanxiao.length > 0) {
              let groupArr = this.groupBy(rData.fanxiao, function (item) {
                return [item.stu_name];
              });
              let arr = new Array();

              groupArr.map(a => {
                let obj = {
                  back_month_07: false,
                  back_month_08: false,
                  back_month_09: false,
                  back_month_10: false,
                  back_month_11: false,
                  back_month_12: false,
                  back_month_01: false,
                  back_month_02: false,
                  back_month_03: false,
                  back_month_04: false,
                  back_month_05: false,
                  back_month_06: false
                };
                let back_month = [];
                a.map((b, indexb) => {
                  obj.stu_name = b.stu_name;
                  let dateArr = b.back_date.split("-");
                  b.back_month = dateArr[1];
                  if (b.back_month == "07") {
                    obj.back_month_07 = true;
                  }
                  if (b.back_month == "08") {
                    obj.back_month_08 = true;
                  }
                  if (b.back_month == "09") {
                    obj.back_month_09 = true;
                  }
                  if (b.back_month == "10") {
                    obj.back_month_10 = true;
                  }
                  if (b.back_month == "11") {
                    obj.back_month_11 = true;
                  }
                  if (b.back_month == "12") {
                    obj.back_month_12 = true;
                  }
                  if (b.back_month == "01") {
                    obj.back_month_01 = true;
                  }
                  if (b.back_month == "02") {
                    obj.back_month_02 = true;
                  }
                  if (b.back_month == "03") {
                    obj.back_month_03 = true;
                  }
                  if (b.back_month == "04") {
                    obj.back_month_04 = true;
                  }
                  if (b.back_month == "05") {
                    obj.back_month_05 = true;
                  }
                  if (b.back_month == "06") {
                    obj.back_month_06 = true;
                  }
                });
                arr.push(obj);
              });

              if (arr.length < 20) {
                let len = 20 - arr.length;
                for (let i = 0; i < len; i++) {
                  arr.push({});
                }
              }
              // console.log(arr)
              this.fanxiao = this.util_spliceArrByN(arr, 20);
              // console.log( this.fanxiao)
            } else {
              let arr = [];
              let len = 20;
              for (let i = 0; i < len; i++) {
                arr.push({});
              }
              this.fanxiao = this.util_spliceArrByN(arr, 20);
            }
          }

          this.$nextTick(() => {
            setTimeout(() => {
              _this.pdfok = true;
            }, 500);
          });
        });
    },

    getParams(name) {
      let n_url = window.location.href;
      n_url = n_url.replace("/#", "");
      if (n_url.indexOf("?") > 0) {
        n_url = n_url.split("?")[1];
      }
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = n_url.match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    //分割数组 arr：原数组  num：分割后每个数组里的数据条数
    util_spliceArrByN(arr, num) {
      var _arr = [];
      while (arr.length) {
        _arr.push(arr.splice(0, num));
      }
      return _arr;
    },
    //数组按某属性分组
    groupBy(array, f) {
      let groups = {};
      array.forEach(function (o) {
        let group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      return Object.keys(groups).map(function (group) {
        return groups[group];
      });
    }
  }
};
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>
